<template>
  <v-container id="upgrade" fluid tag="section" class="text_google">
    <v-row>
      <v-col cols="12" md="12">
        <base-material-card color="primary">
          <template v-slot:heading>
            <h2 class="h1 font-weight-light text_google">
              <v-icon large left>mdi-file-send</v-icon>
              แจ้งสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง
            </h2>
          </template>
          <v-card class="pa-2 ma-6">
            <v-card-title>
              <div class="font-weight-light v-size--x-large">
                <v-icon large left>mdi-account-details</v-icon> ข้อมูลเบื้องต้น
                รหัสรายการอ้างอิง {{ id_ref }}
              </div>
              <v-spacer></v-spacer>
              <v-btn text color="red" @click="VideoDialog()">
                <v-icon large color="red">mdi-video</v-icon>
              </v-btn>
            </v-card-title>
            <v-row>
              <v-col cols="12" sm="6">
                <v-alert
                  border="left"
                  colored-border
                  color="green darken-1"
                  elevation="2"
                  type="info"
                >
                  <h2>วิทยาลัย : {{ colleges_user.user_firstname }}</h2>
                  <h3>รหัสวิทยาลัย : {{ colleges_user.user_code }}</h3>
                  <br />
                </v-alert>
              </v-col>
              <v-col cols="12" sm="6">
                <v-alert
                  border="left"
                  colored-border
                  color="green darken-1"
                  elevation="2"
                  type="info"
                >
                  <h2>
                    ครั้งที่ :
                    {{ period_colleges.period_college_times }} ประจำปี :
                    {{ period_colleges.period_college_year }}
                  </h2>
                  <h3>เขียนที่ : {{ colleges_user.user_firstname }}</h3>
                  <h3>วันที่ยื่นคำร้อง : {{ date_today }}</h3>
                </v-alert>
                <!-- <div
                  class="text-center"
                  v-if="college_manpowers.id_ref !== id_ref"
                >
                  <v-btn
                    large
                    rounded
                    color="warning"
                    @click="cancleCondition()"
                  >
                    ไม่ประสงค์แจ้งสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง
                  </v-btn>
                </div> -->
              </v-col>

              <v-col
                cols="12"
                md="12"
                v-if="
                  college_manpower_reports.college_manpower_reportref === id_ref
                "
              >
                <v-alert
                  border="left"
                  colored-border
                  color="green darken-1"
                  elevation="2"
                  type="info"
                >
                  <div class="text-center">
                    <v-btn
                      color="info"
                      dark
                      v-if="
                        college_manpower_reports.college_manpower_reportfilestatus !=
                          'cf' &&
                          college_manpower_reports.college_manpower_reportfilestatus !=
                            'cc'
                      "
                      :href="
                        '#/college/print_college_manpower_report/?college_manpower_reportref=' +
                          id_ref
                      "
                      target="_blank"
                    >
                      <v-icon>mdi-printer</v-icon>
                      <span>พิมพ์แบบรายงาน</span>
                    </v-btn>
                    <div
                      v-if="
                        college_manpower_reports.college_manpower_reportfile !=
                          '-'
                      "
                    >
                      <v-btn
                        class="mt-1"
                        dark
                        color="teal"
                        @click="
                          viewDocument(
                            college_manpower_reports.college_manpower_reportfile
                          )
                        "
                      >
                        <v-icon dark>
                          mdi-magnify
                        </v-icon>
                        ไฟล์แนบ
                      </v-btn>
                      <div
                        v-if="
                          college_manpower_reports.college_manpower_reportfilestatus ===
                            'cc'
                        "
                        class="text-center pa-2"
                      >
                        <v-chip color="red" dark>
                          <v-icon>mdi-information</v-icon>
                          ขณะนี้รอให้ทางส่วนกลางตรวจสอบการแจ้งยกเลิก</v-chip
                        >
                      </div>
                      <div v-else>
                        <v-btn
                          color="warning"
                          class="mt-1"
                          @click="cancelPDF()"
                        >
                          <v-icon>mdi-close</v-icon> ขอยกเลิกไฟล์แนบ
                        </v-btn>
                      </div>
                    </div>
                    <div v-else class="text-center">
                      <v-form
                        ref="Updatecollege_manpowerform"
                        lazy-validation
                        v-if="
                          period_collegeFiles.period_college_enable_file === '1'
                        "
                      >
                        แนบไฟล์ ที่ได้รับการลงลายมือชื่อรับรอง
                        <v-file-input
                          v-model="college_manpower_reportfile"
                          accept=".pdf"
                          name="college_manpower_reportfile"
                          color="deep-purple accent-4"
                          counter
                          label="ไฟล์ .pdf"
                          placeholder="เลือกแนบไฟล์ PDF"
                          prepend-icon="mdi-paperclip"
                          outlined
                          rounded
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                        <v-btn
                          rounded
                          color="green"
                          @click="UpdateFilecollege_manpower_report()"
                        >
                          <v-icon>mdi-content-save</v-icon> บันทึกไฟล์</v-btn
                        >
                      </v-form>
                    </div>
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-card>

          <div v-if="college_manCount > 0">
            <v-form
              ref="addcollege_manpowerform"
              lazy-validation
              v-if="
                period_colleges.period_college_enable === '1' &&
                  period_colleges.period_college_type === 'college_req'
              "
            >
              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-bold v-size--x-large">
                    <v-icon large left>mdi-flag-letiant</v-icon>
                    <v-icon size="40">mdi-account-group</v-icon>
                    แจ้งสาขาวิชาสำหรับตำแหน่งว่างหรือเปลี่ยนแปลงไปใช้ในกรณีอื่น
                  </div>
                </v-card-title>
                <v-col cols="12" sm="12">
                  <v-card outlined>
                    <div class="pa-2 font-weight-bold" >
                      คำอธิบาย
                    </div>
                    <div class="pa-3">
                      กรณี :
                      <v-icon color="error" size="30">mdi-alert</v-icon>
                      โปรดระบุ : หากเลือกสาขาวิชา กรุณา เหตุผลที่ประสงค์บรรจุฯ
                      กลุ่มวิชานี้
                      <br />

                      กรณี :
                      <v-icon color="error" size="30">mdi-bell-plus</v-icon>
                      เลือกกลุ่มนักเรียน นักศึกษาที่สอดคล้องกับ สาขาวิชาที่บรรจุ

                      <br />

                      กรณี :
                      <v-icon color="success" size="30">mdi-bell-plus</v-icon>
                      เลือกกลุ่มนักเรียน นักศึกษาที่สอดคล้องกับ สาขาวิชาที่บรรจุ
                      เพิ่มเติมได้อีก มากกว่า 1 กลุ่ม

                      <br />
                      ทำรายการให้ถูกต้องครบถ้วนจึงจะปรากฎ ปุ่มบันทึก
                    </div>
                  </v-card>

                  <v-card
                    class="ma-2"
                    outlined
                    v-for="(item, index) in jor18s"
                    :key="item.id"
                  >
                    <v-simple-table background-color="primary" class="mb-5">
                      <thead>
                        <tr class="tr-background" height="70px">
                          <th class="text-center font-weight-bold">
                            ลำดับ
                          </th>
                          <th class="text-center font-weight-bold" colspan="3">
                            สถานศึกษา
                          </th>

                          <th class="text-center font-weight-bold">
                            เลขที่ตำแหน่ง
                          </th>
                          <th class="text-center font-weight-bold">
                            นำมาใช้
                          </th>
                          <th class="text-center font-weight-bold">
                            เลือก
                          </th>
                          <th class="text-center font-weight-bold">
                            สาขาวิชา
                          </th>
                          <th class="text-center font-weight-bold" colspan="2">
                            เหตุผลที่ประสงค์บรรจุฯ กลุ่มวิชานี้
                          </th>
                          <!--    <th class="text-center font-weight-bold">
                            หากประสงค์ กรณีอื่นๆ
                          </th> -->
                        </tr>
                        <tr>
                          <td class="text-center">{{ index + 1 }}</td>
                          <td class="text-center" colspan="3">
                            {{ item.college_name }}
                          </td>

                          <td class="text-center font-weight-bold">
                            {{ item.id_position }}
                          </td>
                          <td class="text-center">{{ item.j18_reason }}</td>
                          <td class="text-center">
                            <div
                              v-if="
                                item.college_manpower_status === 'cf' ||
                                  item.college_manpower_status === 'cc'
                              "
                            >
                              <v-icon color="success">mdi-check-circle</v-icon>
                            </div>
                            <div v-else>
                              <div v-if="item.id_no === editedItem.id_no">
                                <v-icon color="red" class="mr-3" @click="close">
                                  mdi-window-close
                                </v-icon>

                                <v-icon color="green" @click="save()">
                                  mdi-content-save
                                </v-icon>
                              </div>
                              <div v-else>
                                <div
                                  v-if="
                                    parseInt(item.college_manpower_idbranch) >
                                      0 ||
                                      String(item.college_manpower_note)
                                        .length > 5
                                  "
                                >
                                  <v-icon
                                    color="red"
                                    @click="
                                      deleteItem(
                                        item.college_manpower_ref,
                                        item.college_manpower_idposition
                                      )
                                    "
                                  >
                                    mdi-delete
                                  </v-icon>
                                </div>
                                <div v-else>
                                  <v-icon
                                    color="green"
                                    class="mr-3"
                                    @click="editItem(item)"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="text-center">
                              <v-autocomplete
                                v-if="item.id_no === editedItem.id_no"
                                outlined
                                :items="branch_s"
                                item-text="name_branch"
                                item-value="id_branch"
                                v-model="editedItem.college_manpower_idbranch"
                                label="สาขาวิชา"
                                required
                              >
                                <template v-slot:item="{ item }">
                                  <div>
                                    <div>
                                      {{ item.id_branch }} :
                                      {{ item.name_branch }}
                                    </div>
                                  </div>
                                </template>
                              </v-autocomplete>

                              <div v-else>
                                <h3 class="font-weight-bold ma-1">
                                  {{ item.college_manpower_idbranch }} :
                                  {{ item.name_branch }}
                                </h3>

                                <div
                                  v-if="item.college_manpower_status === 'cf' ||
                                  item.college_manpower_status === 'cc'"
                                ></div>
                                <div v-else>
                                  <div
                                    v-if="
                                      parseInt(item.college_manpower_idbranch) >
                                        0
                                    "
                                  >
                                    <div
                                      v-if="
                                        parseInt(
                                          item.college_manpower_idbranch
                                        ) < 200 ||
                                          parseInt(
                                            item.college_manpower_idbranch
                                          ) === 310 ||
                                          parseInt(
                                            item.college_manpower_idbranch
                                          ) === 312 ||
                                          item.college_manpower_comment ===
                                            'ต้องการเปิดสาขาวิชาดังกล่าวเพิ่มเติม'
                                      "
                                    ></div>

                                    <div v-else-if="item.countbstd < 1">
                                      <v-btn
                                        fab
                                        small
                                        color="error"
                                        @click="
                                          rate_work_collegeQuery(
                                            item.college_manpower_id
                                          )
                                        "
                                        ><v-icon>mdi-bell-plus</v-icon></v-btn
                                      >
                                    </div>

                                    <div v-else>
                                      <v-btn
                                        fab
                                        small
                                        color="success"
                                        @click="
                                          rate_work_collegeQuery(
                                            item.college_manpower_id
                                          )
                                        "
                                        ><v-icon>mdi-bell-plus</v-icon></v-btn
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="text-center" colspan="2">
                            <div class="mt-2">
                              <div
                                v-if="
                                  item.id_no === editedItem.id_no &&
                                    parseInt(
                                      editedItem.college_manpower_idbranch
                                    ) > 0
                                "
                              >
                                <v-select
                                  outlined
                                  v-model="editedItem.college_manpower_comment"
                                  label="เหตุผลที่ประสงค์ ระบุ:"
                                  required
                                  :items="college_manpower_comments"
                                  item-text="text"
                                  item-value="value"
                                  :rules="[v => !!v || '']"
                                >
                                </v-select>

                                <v-textarea
                                  v-if="
                                    item.id_no === editedItem.id_no &&
                                      parseInt(
                                        editedItem.college_manpower_idbranch
                                      ) > 0 &&
                                      editedItem.college_manpower_comment ===
                                        'etc'
                                  "
                                  outlined
                                  v-model="
                                    editedItem.college_manpower_commentetc
                                  "
                                  label="เหตุผลที่ประสงค์ ระบุ:"
                                  required
                                  :rules="[v => !!v || '']"
                                >
                                </v-textarea>
                              </div>

                              <div v-else>
                                <div
                                  class="red--text"
                                  v-if="
                                    parseInt(item.college_manpower_idbranch) >
                                      0 &&
                                      String(item.college_manpower_comment)
                                        .length < 5
                                  "
                                >
                                  <v-icon color="error" size="30"
                                    >mdi-alert</v-icon
                                  >
                                  โปรดระบุ
                                </div>

                                <div
                                  class="red--text"
                                  v-else-if="
                                    String(item.college_manpower_comment)
                                      .length < 5 &&
                                      String(item.college_manpower_note)
                                        .length < 5
                                  "
                                >
                                  <v-icon color="error" size="30"
                                    >mdi-alert</v-icon
                                  >
                                  โปรดระบุ
                                </div>

                                <div v-else>
                                  {{ item.college_manpower_comment }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <!--   <td class="text-center">
                            <div>
                              <v-autocomplete
                                v-if="item.id_no === editedItem.id_no"
                                outlined
                                :items="caseidPosition"
                                item-text="text"
                                item-value="value"
                                v-model="editedItem.college_manpower_note"
                                label="ความประสงค์อื่น"
                                required
                              >
                              </v-autocomplete>
                              <div v-else>
                                <div
                                  class="red--text"
                                  v-if="
                                    String(item.college_manpower_comment)
                                      .length < 5 &&
                                      String(item.college_manpower_note)
                                        .length < 5
                                  "
                                >
                                  <v-icon color="warning" size="30"
                                    >mdi-information</v-icon
                                  >
                                  หากประสงค์อื่นๆ
                                </div>

                                {{ item.college_manpower_note }}
                              </div>
                            </div>
                          </td> -->
                        </tr>
                      </thead>
                      <tbody
                        v-for="(child, index) in item.jor18_sub"
                        :key="child.id"
                      >
                        <tr
                          class="tr-background2"
                          v-if="
                            parseInt(index + 1) <= 1 &&
                              child.college_m_brandstd_idtype === 'normal'
                          "
                        >
                          <td class="text-center font-weight-bold">
                            ลำดับ
                          </td>
                          <td class="text-center font-weight-bold" colspan="2">
                            ประเภทวิชา
                          </td>
                          <td class="text-center font-weight-bold">
                            รหัสสาขาวิชา
                          </td>
                          <td class="text-center font-weight-bold" colspan="3">
                            สาขาวิชา
                          </td>

                          <td class="text-center font-weight-bold">
                            ปีหลักสูตร
                          </td>
                          <td class="text-center font-weight-bold">
                            ระดับ
                          </td>
                          <td class="text-center font-weight-bold">
                            ดำเนินการ
                          </td>
                        </tr>

                        <tr
                          class="tr-background2"
                          v-else-if="
                            child.college_m_brandstd_idtype === 'short'
                          "
                        >
                          <td class="text-center font-weight-bold">
                            ลำดับ
                          </td>
                          <td class="text-center font-weight-bold">
                            หลักสูตรระยะสั้น
                          </td>
                          <td class="text-center font-weight-bold">
                            ปี
                          </td>
                          <td class="text-center font-weight-bold" colspan="3">
                            จำนวนชั่วโมง
                          </td>

                          <td class="text-center font-weight-bold">
                            จำนวนผู้เรียน
                          </td>
                          <td class="text-center font-weight-bold">
                            จำนวนห้องเรียน
                          </td>
                          <td class="text-center font-weight-bold">
                            จำนวนชั่วโมงรวม
                          </td>
                          <td class="text-center font-weight-bold">
                            ดำเนินการ
                          </td>
                        </tr>

                        <tr v-if="child.college_m_brandstd_idtype === 'normal'">
                          <td class="text-center">
                            {{ index + 1 }}
                          </td>
                          <td class="text-center" colspan="2">
                            {{ child.type_course }}
                          </td>
                          <td class="text-center">
                            {{ child.id_course_branch }}
                          </td>
                          <td class="text-center" colspan="3">
                            {{ child.branch_name_th }}
                          </td>
                          <td class="text-center">
                            {{ child.year_course }}
                          </td>
                          <td class="text-center">
                            <span v-if="child.rate_work_level_class === '20'"
                              >ปวช.</span
                            >
                            <span
                              v-else-if="child.rate_work_level_class === '30'"
                              >ปวส.</span
                            >
                          </td>
                          <td class="text-center">
                            <div
                              v-if="item.college_manpower_status === 'cf' ||
                                  item.college_manpower_status === 'cc'"
                            ></div>
                            <div v-else>
                              <v-btn
                                @click="
                                  cancelIdbranch(child.college_m_brandstd_id)
                                "
                                color="error"
                                fab
                                small
                                outlined
                                ><v-icon>mdi-delete</v-icon></v-btn
                              >
                            </div>
                          </td>
                        </tr>

                        <tr
                          v-else-if="
                            child.college_m_brandstd_idtype === 'short'
                          "
                        >
                          <td class="text-center">
                            {{ index + 1 }}
                          </td>
                          <td class="text-center">
                            {{ child.short_course_name }}
                          </td>
                          <td class="text-center">
                            {{ child.rate_sc_year }}
                          </td>
                          <td class="text-center" colspan="3">
                            {{ child.hours_teaching }}
                          </td>
                          <td class="text-center">
                            {{ child.rate_work_sc_sum }}
                          </td>
                          <td class="text-center">
                            {{ child.rate_work_sc_room_sum }}
                          </td>
                          <td class="text-center">
                            {{ child.rate_work_sc_hours }}
                          </td>

                          <td class="text-center">
                            <div
                              v-if="item.college_manpower_status === 'cf' ||
                                  item.college_manpower_status === 'cc'"
                            ></div>
                            <div v-else>
                              <v-btn
                                @click="
                                  cancelIdbranch(child.college_m_brandstd_id)
                                "
                                color="error"
                                fab
                                small
                                outlined
                                ><v-icon>mdi-delete</v-icon></v-btn
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-card>
            </v-form>

            <v-form v-else>
              <v-card class="pa-2 ma-2">
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  <h1 class="text-center red--text">
                    ปิดรับการกำหนดสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง
                  </h1>
                </v-alert>
              </v-card>
            </v-form>
            <v-card
              class="pa-1 d-flex justify-center"
              v-if="
                period_colleges.period_college_enable === '1' &&
                  period_colleges.period_college_type === 'college_req' &&
                  coutreportcheck.counta === coutreportcheck.countbranch
              "
            >
              <div>
                <v-row
                  v-if="
                    college_manpower_reports.college_manpower_reportfilestatus !=
                      'cf' &&
                      college_manpower_reports.college_manpower_reportfilestatus !=
                        'cc' &&
                      coutDataSelete < 1
                  "
                >
                  <div class="text-center">
                    <h3 class="red--text">
                      * เมื่อกดปุ่มบันทึกและยืนยันข้อมูลแล้ว
                      ท่านจะไม่สามารถแก้ไขข้อมูลใดๆ ได้
                      กรุณาตรวจสอบข้อมูลให้ถูกต้องการกดปุ่ม บันทึก
                    </h3>
                    <h2 class="success--text">
                      * เมื่อกดปุ่มบันทึกแล้วจะปรากฎปุ่มสำหรับพิมพ์แบบเสนอ
                      สาขาวิชาสำหรับเลขที่ตำแหน่งว่าง
                    </h2>
                  </div>
                </v-row>
                <v-row>
                  <v-col cols="12" align="center">
                    <div
                      v-if="
                        college_manpower_reports.college_manpower_reportref ===
                          id_ref
                      "
                    >
                      <v-btn
                        v-if="
                          college_manpower_reports.college_manpower_reportfilestatus !=
                            'cf' &&
                            college_manpower_reports.college_manpower_reportfilestatus !=
                              'cc'
                        "
                        x-large
                        color="info"
                        dark
                        class="ma-2"
                        :href="
                          '#/college/print_college_manpower_report/?college_manpower_reportref=' +
                            id_ref
                        "
                        target="_blank"
                      >
                        <v-icon>mdi-printer</v-icon>
                        <span>พิมพ์แบบรายงาน</span>
                      </v-btn>

                      <!-- <v-btn
                        v-if="
                          college_manpower_reports.college_manpower_reportfilestatus !=
                            'cf' &&
                            college_manpower_reports.college_manpower_reportfilestatus !=
                              'cc'
                        "
                        x-large
                        color="info"
                        dark
                        class="ma-2"
                        :href="
                          '#/college/print_college_manpower_report_std/?college_manpower_reportref=' +
                            id_ref
                        "
                        target="_blank"
                      >
                        <v-icon>mdi-printer</v-icon>
                        <span>พิมพ์แบบรายงาน 2</span>
                      </v-btn> -->
                    </div>

                    <div v-else>
                      <div v-if="coutDataSelete > 0">
                        <h2 class="red--text">
                          <v-icon size="30" color="red">mdi-alert</v-icon>
                          กรุณาเลือกกลุ่มนักเรียน
                          นักศึกษาที่สอดคล้องกับสาขาวิชาที่เลือก
                          <v-icon size="30" color="red">mdi-bell-plus</v-icon>
                        </h2>
                      </div>
                      <div v-else>
                        <v-btn
                          x-large
                          color="success"
                          dark
                          class="ma-2"
                          @click.stop="confirmsSubmit()"
                        >
                          <v-icon>mdi-content-save</v-icon>
                          <span>บันทึก</span>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>

            <v-card
              outlined
              v-if="
                period_colleges.period_college_enable === '1' &&
                  period_colleges.period_college_type === 'college_req' &&
                  coutreportcheck.counta != coutreportcheck.countbranch
              "
            >
              <h2 class="text-center red--text">
                <v-icon>mdi-alert</v-icon> ทำรายการสำรวจให้ครบถ้วน
                จึงจะสามารถกดบันทึกยืนยันได้ เพื่อทำขั้นตอนถัดไป
              </h2>
            </v-card>
          </div>
          <div v-else>
            <h2 class="text-center red--text">
              ไม่พบเลขที่ตำแหน่งสำรวจในครั้งนี้
            </h2>
          </div>
        </base-material-card>

        <!-- V-model confirm -->
        <v-layout>
          <v-dialog v-model="confirm_dialog" persistent max-width="40%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="primary"
                icon="mdi-content-save"
                title="ยืนยันรายการ"
                class="px-5 py-3 text_google"
              >
              </base-material-card>

              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form
                      ref="deletetransference_locationform"
                      lazy-validation
                    >
                      <v-container grid-list-md>
                        <v-alert outlined type="error" prominent border="left">
                          <h2>
                            เตือน
                          </h2>
                          <h3>
                            หากกดปุ่มบันทึกท่านไม่สามารถ แก้ไขข้อมูลใดๆ ได้แล้ว
                            กรุณาตรวจสอบให้ถูกต้องก่อนการบันทึก
                          </h3>
                        </v-alert>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large rounded @click.stop="confirm_dialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="primary"
                  @click.stop="addcollege_manpower_reportSubmit()"
                  dark
                  rounded
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยัน
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- Video Dialog -->
        <v-layout>
          <v-dialog v-model="VideoDialogYoutube" persistent max-width="80%">
            <v-card>
              <div class="text-center">
                <v-btn text large rounded @click="VideoDialogYoutubeStop()"
                  ><v-icon dark>mdi-close</v-icon></v-btn
                >
                <iframe
                  width="100%"
                  height="600px"
                  src="https://www.youtube.com/embed/iVZy90tWSZg"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model cancleConditionDialog -->
        <v-layout>
          <v-dialog v-model="cancleConditionDialog" persistent max-width="40%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="primary"
                icon="mdi-content-save"
                title="ไม่ประสงค์ กำหนดสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง"
                class="px-5 py-3 text_google"
              >
              </base-material-card>

              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form ref="cancleConditionDialogform" lazy-validation>
                      <v-container grid-list-md>
                        <v-flex md12>
                          <v-file-input
                            v-model="college_manpower_reportfile"
                            accept=".pdf"
                            name="college_manpower_reportfile"
                            color="deep-purple accent-4"
                            counter
                            label="ไฟล์ วาระการประชุม .pdf"
                            placeholder="เลือกแนบไฟล์ วาระการประชุม PDF"
                            prepend-icon="mdi-paperclip"
                            outlined
                            rounded
                            :show-size="1000"
                            :rules="rules"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </v-flex>
                        <v-alert outlined type="error" prominent border="left">
                          <h2>
                            เตือน
                          </h2>
                          <h3>
                            หากกดปุ่มบันทึกท่านไม่สามารถ แก้ไขข้อมูลใดๆ ได้แล้ว
                            กรุณาตรวจสอบให้ถูกต้องก่อนการบันทึก
                          </h3>
                        </v-alert>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large rounded @click.stop="cancleConditionDialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="primary"
                  @click.stop="cancleConditionDialogSubmit()"
                  dark
                  rounded
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยัน
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model pdfcollege_manpower_reportfileDialog -->
        <v-layout row justify-center>
          <v-dialog
            v-model="pdfcollege_manpower_reportfileDialog"
            max-width="80%"
          >
            <v-card class="" elevation="2">
              <embed
                :src="'/HRvecCollegeIdPos/' + pdf_files"
                width="100%"
                height="700px"
              />
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- สาขาวิชา -->
        <v-dialog v-model="college_rate_stdDialog" persistent max-width="80%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="primary"
              icon="mdi-content-save"
              title="เลือกสาขาวิชา ที่ประสงค์บรรจุครู"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                fab
                small
                rounded
                @click.stop="college_rate_stdDialog = false"
                ><v-icon dark>mdi-close</v-icon></v-btn
              >
            </v-card-actions>

            <v-card-text class="text_google">
              <v-card outlined>
                <h2 class="text-center red--text font-weight-bold mt-2">
                  เลขที่ตำแหน่ง :
                  {{ college_manpowerid.college_manpower_idposition }}
                </h2>

                <h3 class="text-center red--text font-weight-bold">
                  {{ college_manpowerid.college_manpower_idbranch }} :
                  {{ college_manpowerid.name_branch }}
                </h3>

                <div class="text-center pa-2">
                  <v-radio-group
                    class="ml-10"
                    v-model="college_manpowerid.college_m_brandstd_idtype"
                    row
                  >
                    <template v-slot:label>
                      <div><strong>ประเภทหลักสุตร</strong></div>
                    </template>
                    <v-radio value="normal">
                      <template v-slot:label>
                        <div>
                          หลักสูตร
                          <strong class="success--text">ปวช./ปวส.</strong>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio
                      value="short"
                      v-if="colleges_user.college_id_code_type_manpower === '6'"
                    >
                      <template v-slot:label>
                        <div>
                          หลักสูตร
                          <strong class="warning--text">ระยะสั้น</strong>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>

                <!-- ปวช/ปวส. -->
                <v-card-text
                  v-if="
                    college_manpowerid.college_m_brandstd_idtype === 'normal'
                  "
                >
                  <v-data-table
                    :items="rate_work_college"
                    :headers="header_branchstd"
                    :search="search_branch"
                  >
                    <template v-slot:top>
                      <div>
                        <v-text-field
                          label="ค้นหาสาขาวิชา"
                          v-model="search_branch"
                          outlined
                        ></v-text-field>
                      </div>
                    </template>
                    <template v-slot:item.rate_work_college_id_class="{ item }">
                      <div>
                        <span v-if="item.rate_work_college_id_class === '20'"
                          >ปวช.</span
                        >
                        <span v-else>ปวส.</span>
                      </div>
                    </template>
                    <template v-slot:item.actionSelect="{ item }">
                      <div>
                        <v-btn
                          v-if="
                            item.checkIdbrand === item.rate_id_course_branch
                          "
                          @click="cancelIdbranch(item.checkIdbrandstd_id)"
                          color="error"
                          outlined
                          icon
                          small
                        >
                          <v-icon>mdi-minus-circle</v-icon></v-btn
                        >

                        <v-btn
                          v-else
                          @click="insertIdbranch(item.rate_id_course_branch)"
                          color="success"
                          outlined
                          icon
                          small
                        >
                          <v-icon>mdi-plus-circle</v-icon></v-btn
                        >
                      </div>
                    </template>
                  </v-data-table>
                </v-card-text>

                <!-- ระยะสั้น -->
                <v-card-text
                  v-else-if="
                    college_manpowerid.college_m_brandstd_idtype === 'short'
                  "
                >
                  <v-data-table
                    :items="rate_work_sc"
                    :headers="header_branchstdsc"
                    :search="search_branch"
                  >
                    <template v-slot:top>
                      <div>
                        <v-text-field
                          label="ค้นหาสาขาวิชา"
                          v-model="search_branch"
                          outlined
                        ></v-text-field>
                      </div>
                    </template>

                    <template v-slot:item.actionSelect="{ item }">
                      <div>
                        <v-btn
                          v-if="item.checkIdbrand === item.rate_sc_id"
                          @click="cancelIdbranch(item.checkIdbrandstd_id)"
                          color="error"
                          outlined
                          icon
                          small
                        >
                          <v-icon>mdi-minus-circle</v-icon></v-btn
                        >

                        <v-btn
                          v-else
                          @click="insertIdbranch(item.rate_sc_id)"
                          color="success"
                          outlined
                          icon
                          small
                        >
                          <v-icon>mdi-plus-circle</v-icon></v-btn
                        >
                      </div>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                fab
                small
                rounded
                @click.stop="college_rate_stdDialog = false"
                ><v-icon dark>mdi-close</v-icon></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      el: "#todo-list-example",
      conditions_branchs: [],
      loading: true,
      ApiKey: "HRvec2021",
      colleges_user: [],
      colleges: {},

      branch_s: [],
      search: "",
      search_branch: "",

      VideoDialogYoutube: false,
      confirm_dialog: false,

      cancleConditionDialog: false,
      college_rate_stdDialog: false,
      user: {},
      conditions_branch: [],
      conditions_branchs: [],
      college_manpowers: [],
      addconditions_branch: {},
      addcollege_manpower: {},
      editconditions_branch: [],

      period_colleges: [],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },

      header: [
        { text: "สถานศึกษา", align: "left", value: "college_name" },
        { text: "ตำแหน่ง", align: "center", value: "positions" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
        { text: "นำมาใช้", align: "center", value: "j18_reason" },
        { text: "เลือก", align: "center", value: "actionSelect" },
        {
          text: "สาขาวิชา",
          align: "left",
          value: "college_manpower_idbranch"
        },
        {
          text: "เหตุผลที่ประสงค์บรรจุฯ กลุ่มวิชานี้",
          align: "left",
          value: "college_manpower_comment"
        },
        {
          text: "หากประสงค์ กรณีอื่นๆ ",
          align: "left",
          value: "college_manpower_note"
        }
      ],

      model: null,

      pagination: {},

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      period_college_enable: "1",
      period_college_enable_file: "1",
      branch_sub_ds: [],

      college_manpower_reportfile: null,
      updateFileconditions: {},

      interval: {},
      period_collegeFiles: [],
      value: 0,
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      college_manpowerCC: {},
      college_manpower_reportCC: {},
      linealerts: {},
      pdf_files: [],
      pdfcollege_manpower_reportfileDialog: false,
      jor18s: [],
      editedIndex: -1,
      editedItem: {
        id_no: 0,
        college_manpower_idbranch: "",
        name_branch: "",
        college_manpower_status: ""
      },
      editedItemUpdate: {},
      defaultItem: {
        id_no: 0,
        college_manpower_idbranch: "",
        name_branch: "",
        college_manpower_status: ""
      },
      deletedItemmat: {},
      deletedItemmcm: {},
      addcollege_manpower: {},
      addcollege_manpower_report: {},
      college_manCount: "",
      college_manpower_reports: [],
      update_college_manpower: {},
      caseidPosition: [
        { text: "สอบแข่งขัน", value: "สอบแข่งขัน" },
        { text: "สอบคัดเลือก", value: "สอบคัดเลือก" },
        { text: "ย้ายกรณีปกติ", value: "ย้ายกรณีปกติ" },
        { text: "ย้ายกรณีพิเศษ", value: "ย้ายกรณีพิเศษ" },
        { text: "โอนย้าย", value: "โอนย้าย" }
      ],
      rate_work_collegeyears: [],
      rate_work_college: [],
      rate_work_sc: [],
      college_manpowerid: [],

      header_branchstd: [
        /* { text: "สถานศึกษา", align: "left", value: "college_name" }, */
        { text: "ประเภทวิชา", align: "left", value: "name_type_course" },
        {
          text: "รหัสสาขาวิชา",
          align: "center",
          value: "rate_id_course_branch"
        },
        { text: "สาขาวิชา", align: "center", value: "branch_name_th" },
        { text: "ระดับ", align: "center", value: "rate_work_college_id_class" },
        { text: "ปวช.1/ปวส.1", align: "center", value: "std_level_1" },
        { text: "ปวช.2/ปวส.2", align: "center", value: "std_level_2" },
        { text: "ปวช.3", align: "center", value: "std_level_3" },

        { text: "เลือก", align: "center", value: "actionSelect" }
      ],

      header_branchstdsc: [
        /* { text: "สถานศึกษา", align: "left", value: "college_name" }, */
        { text: "วิชา", align: "left", value: "short_course_name" },
        {
          text: "ปี",
          align: "center",
          value: "rate_sc_year"
        },
        { text: "จำนวนชั่วโมง", align: "center", value: "hours_teaching" },
        { text: "จำนวนผู้เรียน", align: "center", value: "rate_work_sc_sum" },
        {
          text: "จำนวนห้องเรียน",
          align: "center",
          value: "rate_work_sc_room_sum"
        },
        {
          text: "จำนวนชั่วโมงรวม",
          align: "center",
          value: "rate_work_sc_hours"
        },

        { text: "เลือก", align: "center", value: "actionSelect" }
      ],

      addcollege_m_brandstd: {},
      addcollege_m_brandstd: {},
      college_manpower_comments: [
        {
          text: "เพื่อทดแทนครูสาขาวิชาที่ลาออก โอน ย้าย เสียชีวิต เกษียณ",
          value: "เพื่อทดแทนครูสาขาวิชาที่ลาออก โอน ย้าย เสียชีวิต เกษียณ"
        },
        {
          text: "สถานศึกษาขาดแคลนอัตรากำลังในกลุ่มสาขาดังกล่าว",
          value: "สถานศึกษาขาดแคลนอัตรากำลังในกลุ่มสาขาดังกล่าว"
        },
        { text: "ลดภาระการจ้างครูผู้สอน", value: "ลดภาระการจ้างครูผู้สอน" },
        {
          text: "มีจำนวนผู้เรียนเพิ่มขึ้น ส่งผลให้ผู้สอนในสาขาวิชาไม่เพียงพอ",
          value: "มีจำนวนผู้เรียนเพิ่มขึ้น ส่งผลให้ผู้สอนในสาขาวิชาไม่เพียงพอ"
        },
        {
          text: "ต้องการเปิดสาขาวิชาดังกล่าวเพิ่มเติม",
          value: "ต้องการเปิดสาขาวิชาดังกล่าวเพิ่มเติม"
        },
        { text: "อื่นๆ โปรดระบุ", value: "etc" }
      ],

      coutreportcheck: [],
      coutDataSelete: ""
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },

  async mounted() {
    await this.periodQuery();
    await this.timeCheck();
    await this.branchQuery();
    await this.colleges_userQuery();
    await this.j18Query();
    await this.college_manpower_reportQuery();
    await this.rate_work_collegeYear();
  },

  methods: {
    async cancelIdbranch(checkIdbrandstd_id) {
      Swal.fire({
        title: "คุณต้องการลบรายการนี้?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรายการ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let result = await this.$http.post("college_m_brandstd.delete.php", {
            ApiKey: this.ApiKey,
            college_m_brandstd_id: checkIdbrandstd_id
          });

          let result_check = await this.$http.post("rate_work_college.php", {
            ApiKey: this.ApiKey,
            rate_work_college_code: this.college_manpowerid
              .college_manpower_idcollege,
            rate_work_college_year: this.rate_work_collegeyears
              .rate_work_college_year,
            college_m_brandstd_ref: this.id_ref,
            college_m_brandstd_idposition: this.college_manpowerid
              .college_manpower_idposition
          });
          this.rate_work_college = result_check.data;

          let result_sc = await this.$http.post("rate_work_sc.php", {
            ApiKey: this.ApiKey,
            rate_sc_college_code: this.college_manpowerid
              .college_manpower_idcollege,
            rate_sc_year: this.rate_work_collegeyears.rate_work_college_year
          });
          this.rate_work_sc = result_sc.data;

          await this.j18Query();
        }
      });
    },

    async insertIdbranch(rate_id_course_branch) {
      this.addcollege_m_brandstd.ApiKey = this.ApiKey;
      this.addcollege_m_brandstd.college_m_brandstd_ref = this.id_ref;
      this.addcollege_m_brandstd.college_m_brandstd_time = this.college_manpowerid.college_manpower_time;
      this.addcollege_m_brandstd.college_m_brandstd_year = this.college_manpowerid.college_manpower_year;
      this.addcollege_m_brandstd.college_m_brandstd_idcollege = this.college_manpowerid.college_manpower_idcollege;
      this.addcollege_m_brandstd.college_m_brandstd_idposition = this.college_manpowerid.college_manpower_idposition;
      this.addcollege_m_brandstd.college_m_brandstd_idbranh = this.college_manpowerid.college_manpower_idbranch;

      this.addcollege_m_brandstd.college_m_brandstd_idtype = this.college_manpowerid.college_m_brandstd_idtype;
      this.addcollege_m_brandstd.college_m_brandstd_idstdbranh = rate_id_course_branch;

      this.addcollege_m_brandstd.college_m_brandstd_status = "save";
      let result = await this.$http.post(
        "college_m_brandstd.insert.php",
        this.addcollege_m_brandstd
      );

      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });

        let result = await this.$http.post("rate_work_college.php", {
          ApiKey: this.ApiKey,
          rate_work_college_code: this.college_manpowerid
            .college_manpower_idcollege,
          rate_work_college_year: this.rate_work_collegeyears
            .rate_work_college_year,
          college_m_brandstd_ref: this.id_ref,
          college_m_brandstd_idposition: this.college_manpowerid
            .college_manpower_idposition
        });
        this.rate_work_college = result.data;

        let result_sc = await this.$http.post("rate_work_sc.php", {
          ApiKey: this.ApiKey,
          rate_sc_college_code: this.college_manpowerid
            .college_manpower_idcollege,
          rate_sc_year: this.rate_work_collegeyears.rate_work_college_year
        });
        this.rate_work_sc = result_sc.data;

        await this.j18Query();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    async rate_work_collegeYear() {
      let result = await this.$http.post("rate_work_college.php", {
        ApiKey: this.ApiKey,
        last_year: "ok"
      });
      this.rate_work_collegeyears = result.data;
    },

    async rate_work_collegeQuery(college_manpower_id) {
      let result_cman = await this.$http.post("college_manpower.php", {
        ApiKey: this.ApiKey,
        college_manpower_id: college_manpower_id
      });
      this.college_manpowerid = result_cman.data;

      let result = await this.$http.post("rate_work_college.php", {
        ApiKey: this.ApiKey,
        rate_work_college_code: this.college_manpowerid
          .college_manpower_idcollege,
        rate_work_college_year: this.rate_work_collegeyears
          .rate_work_college_year,
        college_m_brandstd_ref: this.id_ref,
        college_m_brandstd_idposition: this.college_manpowerid
          .college_manpower_idposition
      });
      this.rate_work_college = result.data;

      let result_sc = await this.$http.post("rate_work_sc.php", {
        ApiKey: this.ApiKey,
        rate_sc_college_code: this.college_manpowerid
          .college_manpower_idcollege,
        rate_sc_year: this.rate_work_collegeyears.rate_work_college_year
      });
      this.rate_work_sc = result_sc.data;

      this.college_rate_stdDialog = true;
    },
    async deleteItem(college_manpower_ref, college_manpower_idposition) {
      this.deletedItemmat.ApiKey = this.ApiKey;
      this.deletedItemmat.college_manpower_ref = college_manpower_ref;
      this.deletedItemmat.college_manpower_idposition = college_manpower_idposition;
      Swal.fire({
        title: "คุณต้องการลบรายการนี้?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรายการ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let resultupdatemat = await this.$http.post(
            "college_manpower.delete.php",
            this.deletedItemmat
          );

          let data = {
            ApiKey: this.ApiKey,
            college_m_brandstd_ref: college_manpower_ref,
            college_m_brandstd_idposition: college_manpower_idposition
          };
          let resultu_college_m_brandstd = await this.$http.post(
            "college_m_brandstd.deleteidbranch.php",
            data
          );
          resultu_college_m_brandstd.data;
        }
        this.j18Query();
      });
    },

    async college_manpower_reportQuery() {
      let result = await this.$http.post("college_manpower_report.php", {
        ApiKey: this.ApiKey,
        college_manpower_reportref: this.id_ref
      });
      this.college_manpower_reports = result.data;
    },
    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async editItem(item) {
      this.editedIndex = this.jor18s.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    async save() {
      Object.assign(this.jor18s[this.editedIndex], this.editedItem);

      if (this.$refs.addcollege_manpowerform.validate()) {
        if (this.editedItem.college_manpower_comment == "etc") {
          this.addcollege_manpower.college_manpower_comment = this.editedItem.college_manpower_commentetc;
        } else {
          this.addcollege_manpower.college_manpower_comment = this.editedItem.college_manpower_comment;
        }
        this.addcollege_manpower.ApiKey = this.ApiKey;
        this.addcollege_manpower.college_manpower_ref = this.id_ref;
        this.addcollege_manpower.college_manpower_time = this.period_colleges.period_college_times;
        this.addcollege_manpower.college_manpower_year = this.period_colleges.period_college_year;
        this.addcollege_manpower.college_manpower_idcollege = this.editedItem.j18college_code;
        this.addcollege_manpower.college_manpower_idposition = this.editedItem.id_position;
        this.addcollege_manpower.college_manpower_idbranch = this.editedItem.college_manpower_idbranch;
        this.addcollege_manpower.college_manpower_note = this.editedItem.college_manpower_note;

        this.addcollege_manpower.college_manpower_status = "ca";

        let resultmat = await this.$http.post(
          "college_manpower.insert.php",
          this.addcollege_manpower
        );

        if (resultmat.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          await this.j18Query();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ทำรายการซ้ำ",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.close();
      }
    },

    async j18Query() {
      this.loading = true;
      let result = await this.$http
        .post("jor18.php", {
          ApiKey: this.ApiKey,
          college_manpower_ref: this.id_ref,
          j18college_code: this.colleges_user.user_code,
          idpostion_null: "Ok"
        })
        .finally(() => (this.loading = false));
      this.jor18s = result.data;

      let data = result.data;
      let counta = 0;
      let countbranch = 0;
      let countselete = 0;

      data.forEach(value => {
        counta += 1;
        if (parseInt(value.college_manpower_idbranch) > 0) {
          countbranch += 1;
        }
        if (parseInt(value.college_manpower_idbranch) > 0) {
          if (
            parseInt(value.college_manpower_idbranch) < 200 ||
            parseInt(value.college_manpower_idbranch) === 310 ||
            parseInt(value.college_manpower_idbranch) === 312 ||
            value.college_manpower_comment ===
              "ต้องการเปิดสาขาวิชาดังกล่าวเพิ่มเติม"
          ) {
          } else if (parseInt(value.countbstd) < 1) {
            countselete += 1;
          }
        }
      });
      this.coutreportcheck.counta = counta;
      this.coutreportcheck.countbranch = countbranch;
      this.coutDataSelete = countselete;

      if (counta < 1) {
        Swal.fire({
          icon: "error",
          title: "ไม่พบเลขที่ตำแหน่งสำรวจในครั้งนี้",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/college");
          }
        });
      }
      this.college_manCount = counta;
    },
    async viewDocument(college_manpower_reportfile) {
      this.pdf_files = college_manpower_reportfile;
      this.pdfcollege_manpower_reportfileDialog = true;
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async periodQuery() {
      let result_period_college;
      let result_period_filecollege;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "701"
      });
      this.period_colleges = result_period_college.data;

      result_period_filecollege = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable_file: "1",
        period_college_id: "701"
      });
      this.period_collegeFiles = result_period_filecollege.data;

      if (
        this.period_colleges.period_college_enable != "1" &&
        this.period_collegeFiles.period_college_enable_file != "1"
      ) {
        Swal.fire({
          icon: "error",
          title: "ยังไม่มีกำหนดการ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/college");
          }
        });
      }
    },

    async timeCheck() {
      let dateNow = new Date();
      let dateStop = new Date(
        this.period_colleges.period_college_stop + " 16:30:00"
      );

      let dateFileStop = new Date(
        this.period_colleges.period_college_attactmentstop + " 16:30:00"
      );

      let dateNows = dateNow.getTime();
      let dateStops = dateStop.getTime();
      let dateFileStops = dateFileStop.getTime();

      this.interval = setInterval(async () => {
        if (dateNows > dateStops) {
          await this.periodUpdate();
          await this.periodQuery();
        }

        if (dateNows > dateFileStops) {
          await this.periodFileUpdate();
          await this.periodQuery();
        }
      }, 3000);
    },

    async periodUpdate() {
      this.period_colleges.ApiKey = this.ApiKey;
      this.period_colleges.period_college_enable = "0";
      let result = await this.$http.post(
        "period_college.update.php",
        this.period_colleges
      );
    },

    async periodFileUpdate() {
      this.period_colleges.ApiKey = this.ApiKey;
      this.period_colleges.period_college_enable_file = "0";
      let result = await this.$http.post(
        "period_college.update.php",
        this.period_colleges
      );
    },

    async branchQuery() {
      let result_branch;
      result_branch = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branch_s = result_branch.data;
    },

    async addcollege_manpower_reportSubmit() {
      this.addcollege_manpower_report.ApiKey = this.ApiKey;
      this.addcollege_manpower_report.college_manpower_reportref = this.id_ref;
      this.addcollege_manpower_report.college_manpower_reporttime = this.period_colleges.period_college_times;
      this.addcollege_manpower_report.college_manpower_reportyear = this.period_colleges.period_college_year;
      this.addcollege_manpower_report.college_manpower_reportcollege = this.colleges_user.user_code;
      this.addcollege_manpower_report.college_manpower_reportcountpos = this.college_manCount;
      this.addcollege_manpower_report.college_manpower_reportfile = "-";
      this.addcollege_manpower_report.college_manpower_reportfilestatus = "-";
      this.addcollege_manpower_report.college_manpower_reporttimestamp = new Date();

      let result = await this.$http.post(
        "college_manpower_report.insert.php",
        this.addcollege_manpower_report
      );

      if (result.data.status == true) {
        Swal.fire({
          title: "ดำเนินการข้อมูลเรียบร้อย",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });

        this.update_college_manpower.ApiKey = this.ApiKey;
        this.update_college_manpower.college_manpower_ref = this.id_ref;
        this.update_college_manpower.college_manpower_status = "cf";

        let resultmat = await this.$http.post(
          "college_manpower.updateidref.php",
          this.update_college_manpower
        );
        await this.j18Query();
        await this.college_manpower_reportQuery();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.confirm_dialog = false;
    },

    async cancelPDF() {
      Swal.fire({
        title: "ขอยกเลิกการแนบไฟล์",
        text: "เนื่องจากแนบไฟล์ผิดพลาด",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.college_manpowerCC.ApiKey = this.ApiKey;
          this.college_manpowerCC.college_manpower_ref = this.id_ref;
          this.college_manpowerCC.college_manpower_status = "cc";

          let resultup = await this.$http.post(
            "college_manpower.updateidref.php",
            this.college_manpowerCC
          );

          this.college_manpower_reportCC.ApiKey = this.ApiKey;
          this.college_manpower_reportCC.college_manpower_reportref = this.id_ref;
          this.college_manpower_reportCC.college_manpower_reportfilestatus =
            "cc";

          let resultuprp = await this.$http.post(
            "college_manpower_report.update.php",
            this.college_manpower_reportCC
          );

          if (resultup.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });

            await this.colleges_userQuery();
            await this.j18Query();
            await this.college_manpower_reportQuery();

            this.linealerts.ApiKey = this.ApiKey;
            this.linealerts.user_firstname = this.colleges_user.user_firstname;
            this.linealerts.details =
              "งานอัตรากำลัง : วิทยาลัยขอยกเลิกไฟล์แนบ กำหนดสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง";
            this.linealerts.datetime = this.date_today;

            let result_line = await this.$http.post(
              "linenotify_hr.php",
              this.linealerts
            );

            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "college_manpower CC";
            this.data_syslog.page_log = "conditions_branch";
            this.data_syslog.table_log = "college_manpower.cc";
            this.data_syslog.detail_log = this.id_ref;
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async cancleCondition() {
      this.cancleConditionDialog = true;
    },
    async VideoDialogYoutubeStop() {
      var videos = document.querySelectorAll("iframe, video");
      Array.prototype.forEach.call(videos, function(video) {
        if (video.tagName.toLowerCase() === "video") {
          video.pause();
        } else {
          var src = video.src;
          video.src = src;
        }
      });
      this.VideoDialogYoutube = false;
    },
    async VideoDialog() {
      this.VideoDialogYoutube = true;
    },

    async colleges_userQuery() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("admin.php", {
          ApiKey: this.ApiKey,
          user_name: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.colleges_user = result.data;
    },

    async confirmsSubmit() {
      this.confirm_dialog = true;
    },

    async branch_sub_dQueryAll(index, id_branch) {
      this.loading = true;
      let result = await this.$http
        .post("branch_sub_d.php", {
          ApiKey: this.ApiKey,
          id_main_branch: id_branch
        })
        .finally(() => (this.loading = false));
      this.branch_sub_ds = result.data;
    },

    async UpdateFilecollege_manpower_report() {
      let result = "";
      let uploaded = null;
      this.updateFileconditions.ApiKey = this.ApiKey;
      this.updateFileconditions.college_manpower_reportref = this.id_ref;
      if (this.$refs.Updatecollege_manpowerform.validate()) {
        if (this.college_manpower_reportfile != "") {
          let formData = new FormData();
          let filename = this.id_ref + "_" + this.time_stamp + "_condition.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.college_manpower_reportfile);
          formData.append("filename", "../HRvecCollegeIdPos/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          this.updateFileconditions.college_manpower_reportfile = filename;
        }
      }
      this.updateFileconditions.college_manpower_reportfilestatus = "cf";

      result = await this.$http.post(
        "college_manpower_report.update.php",
        this.updateFileconditions
      );
      if (result.data.status || uploaded) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.colleges_userQuery();
        await this.j18Query();
        await this.college_manpower_reportQuery();
      } else {
        if (!uploaded) {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ไฟล์ที่แนบไม่ถูกต้อง";
          this.snackbar.show = true;
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    },

    async cancleConditionDialogSubmit() {
      let result = "";
      let uploaded = null;
      this.addcollege_manpower.ApiKey = this.ApiKey;
      this.addcollege_manpower.id_ref = this.id_ref;
      this.addcollege_manpower.time_s = this.period_colleges.period_college_times;
      this.addcollege_manpower.year_s = this.period_colleges.period_college_year;
      this.addcollege_manpower.status_confirm = "needless";
      this.addcollege_manpower.user_code = this.colleges_user.user_code;
      this.addcollege_manpower.date_time = this.date_today;

      if (this.$refs.cancleConditionDialogform.validate()) {
        if (this.college_manpower_reportfile != "") {
          let formData = new FormData();
          let filename = this.id_ref + "conditionCan.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.college_manpower_reportfile);
          formData.append("filename", "../HRvecCollegeIdPos/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.addcollege_manpower.college_manpower_reportfile = filename;
            result = await this.$http.post(
              "college_manpower.insert.php",
              this.addcollege_manpower
            );
            if (result.data.status || uploaded) {
              Swal.fire({
                icon: "success",
                title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });
              await this.colleges_userQuery();
            }
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
      } else {
        if (!uploaded) {
          Swal.fire({
            icon: "error",
            title: "กรุณาแนบไฟล์ให้ถูกต้อง",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.cancleConditionDialog = false;
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    id_ref() {
      let id_ref_cr;
      id_ref_cr =
        "req_" +
        this.period_collegeFiles.period_college_times +
        "" +
        this.period_collegeFiles.period_college_year +
        "" +
        this.colleges_user.user_name;
      return id_ref_cr;
    },

    time_stamp() {
      let time = Date.now();
      return time;
    },


    date_today() {
      let monthNames = [
        "",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ];
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;

      today = dd + " " + monthNames[parseInt(mm)] + " " + yyyy;
      return today;
    }
  },
  watch: {}
};
</script>
<style>
.textfeild {
  border: none;
  border-color: aliceblue;
}
.tdh {
  height: 5px;
}
.tr-background {
  background-color: rgb(171, 237, 171);
}
.tr-background2 {
  background-color: rgb(240, 246, 240);
}
</style>
